import React from "react";

import {observer} from "mobx-react";

import {observable} from "mobx";
import {Button} from "./Button";
const bodyScrollLock = require('body-scroll-lock');

export let ModalState = observable({
  opened: null
});

@observer
export class ModalBtn extends React.Component {
  toggle = () => {
    if (!this.props.loading)
      ModalState.opened = !ModalState.opened;
  };

  modal(props) {
    if (ModalState.opened)
      return <Modal onClose={this.toggle} {...props}>
        {this.props.children}
      </Modal>;
  }

  render() {
    let {title, btnProps, ...props} = this.props;

    btnProps = btnProps || { css: "is-link"} ;
    btnProps.title = title;

    return <>{this.modal(props)}<Button { ...btnProps} onClick={this.toggle}/></>
  }
}

export class Modal extends React.Component {
  inner() {
    let {children} = this.props;

    let content = children;

    return <div className="modal-card-body">
      {content}
      {this.actions()}
    </div>;
  }

  componentDidMount() {
    let el = document.querySelector(".modal");

    bodyScrollLock.disableBodyScroll(el);
  }

  componentWillUnmount() {
    let el = document.querySelector(".modal");

    bodyScrollLock.enableBodyScroll(el);
  }

  actions() {
    if (this.props.loading)
      return <><Button css="is-loading is-white" /><div className="is-overlay" style={{backgroundColor: "rgba(0,0,100,0.2)"}}/></>;
    else
      return this.btns();
  }

  cancelBtn() {
    let {cancelBtn, onClose} = this.props;
    if (cancelBtn)
      return <Button title="cancel" css="is-white" onClick={onClose} key="cancel"/>
  }

  btns() {
    let {btns} = this.props;

    if (btns) {
      return <div className="buttons is-right" style={{marginTop: "1rem"}}>{this.cancelBtn()}{btns}</div>
    }
  }

  render() {
    let {onClose, maxWidth, cancelBtn} = this.props;

    let css = "modal is-active";

    return <div className={css}>
      <div className="modal-background" onClick={onClose}/>
      <div className="modal-card" style={{maxWidth}}>
        {this.inner()}
      </div>
      {<button className="modal-close is-large" aria-label="close" onClick={onClose}/>}
    </div>;
  }
}
