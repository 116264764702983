import React from "react";

export const Button = ({title, css, loading, ...props}) => {
  css = "button " + css;

  if (loading)
    css += " is-loading";

  return <button className={css} {...props}>{title}</button>;
};
